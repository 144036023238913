export default [
  {
    user_id: "0F8JIqi4zwvb77FGz6Wt",
    user_name_full: "Heinz-Georg Fiedler",
    user_avatar: {
      src: "https://randomuser.me/api/portraits/men/81.jpg",
    },
  },
  {
    user_id: "0P6E1d4nr0L1ntW8cjGU",
    user_name_full: "Katie Hughes",
    user_avatar: {
      src: "https://randomuser.me/api/portraits/women/74.jpg",
    },
  },
  {
    user_id: "1Lkk06cOUCkiAsUXFLMN",
    user_name_full: "Vetle Aasland",
    user_avatar: {
      src: "https://randomuser.me/api/portraits/men/97.jpg",
    },
  },
  {
    user_id: "1OuR3CWOEsfISTpFxsG7",
    user_name_full: "Dylan Vasquez",
    user_avatar: {
      src: "https://randomuser.me/api/portraits/men/66.jpg",
    },
  },
  {
    user_id: "1pRsh5nXDIH3pjEOZ17A",
    user_name_full: "Margarita Vicente",
    user_avatar: {
      src: "https://randomuser.me/api/portraits/women/5.jpg",
    },
  },
  {
    user_id: "3JAf8R85oIlxXd58Piqk",
    user_name_full: "Joey Oliver",
    user_avatar: {
      src: "https://randomuser.me/api/portraits/men/61.jpg",
    },
  },
  {
    user_id: "5aZRSdkcBOM6j3lkWEoP",
    user_name_full: "Lilja Lampinen",
    user_avatar: {
      src: "https://randomuser.me/api/portraits/women/50.jpg",
    },
  },
  {
    user_id: "5tVxgsqPCjv2Ul5Rc7gw",
    user_name_full: "Abigail Liu",
    user_avatar: {
      src: "https://randomuser.me/api/portraits/women/83.jpg",
    },
  },
  {
    user_id: "6wy6UNkZueJfIUfq88d5",
    user_name_full: "Melanie",
    user_avatar: {
      src: "https://randomuser.me/api/portraits/women/32.jpg",
    },
  },
  {
    user_id: "7DbXNPWlNDR4QYVvFZjr",
    user_name_full: "Evan Carlson",
    user_avatar: {
      src: "https://randomuser.me/api/portraits/men/80.jpg",
    },
  },
];
